export const ROUTES_PT = {
  companies: 'empresas',
  editCompanies: 'empresas/editar',
  editClubSegment: 'editar',
  shareClubSegment: 'compartilhar',
  companyClubParam: 'clubeId',

  play: {
    base: 'jogar',
    game: 'jogar/:id',
    raffle: 'jogar/:id/:raffle',
    lotteryNumberParam: 'numero',
    manual: 'manual',
    rules: 'regras',
    share: 'compartilhar',
    sharePercents: 'compartilhar/porcentagens',
    shareOptions: 'compartilhar/Opcoes',
    address: 'endereco',
    shipment: 'envio',
    collection: 'colecao',
    booth: 'mediador',
    manualMode: {
      booths: 'mediadores',
      map: 'mediador/:boothId',
      location: 'localizacao',
      rules: 'regras',
    },
  },

  clubs: {
    base: 'jogar/clubes',
    clubSegment: 'clubes',
    newClub: 'jogar/clubes/:gameId/:raffleId/:tabId/novo',
    newSegment: 'novo',
    editClub: 'jogar/clubes/:gameId/:raffleId/:tabId/editar/:clubId',
    editSegment: 'editar',
    clubSms: 'jogar/clubes/:gameId/:raffleId/:tabId/compartilhar/sms',
    shareSegment: 'compartilhar',
    shareQueryParam: 'compartilhar',
    clubParam: 'clube',
    helpSegment: 'ajuda',

    list: ':gameId/:raffleId/:tabId',
    tabs: {
      all: 'todos',
      fixed: 'fixo',
      solidary: 'solidary',
      rockets: 'foguetes',
      companies: 'empresas',
      private: 'privado',
      share: 'compartilhar',
      shareSms: 'sms',
      contacts: 'contatos-do-google',
    },

    update: {
      image: 'imagem',
      book: 'livro',
      description: 'descricao',
    },
    new: {
      image: 'imagem',
      help: 'ajuda',
      tickets: 'ingressos',
      booths: 'estandes',
      options: 'opcoes',
      map: 'mapa',
      completed: 'completado',
    },
    register: {
      base: ':code',
      help: 'ajuda',
      register: 'registrar',
      login: 'login',
      terms: 'termos',
      data: 'dados',
      phone: 'telefone',
      sms: 'sms',
      load: 'carregar',
      tpv: 'carregar/tpv',
      remember: 'login/lembrar',
    },
  },

  tickets: {
    ticket: ':id',
    subscription: 'assinatura',
    subscribe: 'inscrever',
    unsubscribe: 'desinscrever',
    archive: 'arquivo',
    share: 'compartilhar',
    shareGroupSegment: 'grupos',
    shareContactsSegment: 'contatos',
    sharePercents: 'compartilhar/porcentagens',
    shareOptions: 'compartilhar/opcoes',
    buyMore: 'comprar',
    edit: 'editar',
    info: 'info',
    repeat: 'repetir',
    proof: 'comprovacao',
    proofData: 'dados',
    download: 'download',
    returnPrize: 'devolver-premio',
    ship: 'enviar',
    result: 'resultado',
    qr: 'qr',
    image: 'imagem',
    collection: 'colecao',
    delete: 'excluir',
    modalParam: 'modal',
  },

  booths: 'estandes',
  boothParam: 'estande',
  retailers: 'mediadores',
  check: 'verificar',
  checkParams: {
    type: 'tipo',
    voucher: 'vale',
    code: 'codigo',
  },
  contactUs: 'contato',
  shipment: 'envio',
  notifications: 'notificacoes',
  faq: 'perguntas-frequentes',
  promos: 'promocoes',
  promoParam: 'codigo',
  redirect: 'redirecionamento/:target',
  redirectSegment: 'redirecionamento',
  legal: 'legal',
  plus18: 'menores-de-18',
  responsibleGame: 'jogo-responsavel',
  lotteryScams: 'esquemas-de-lotaria',
  termsLimitLoad: 'termos-limite-de-carregamento',
  selfExclusionInfo: 'autoexclusao-info',
  register: 'usuario/registrar',
  registerSegment: 'registrar',
  prizes: 'premios',
  prizeParam: 'indice',
  prizeView: ':index',
  sponsor: 'patrocinador',
  language: 'linguagem',

  money: {
    balance: 'saldo',
    load: 'carregar',
    priceParam: 'preco',
    screenParam: 'tela',
    paymentMethodParam: 'paymentMethod',
    selectMethod: 'adicionar',
    transfer: 'transferir',
    transferPreview: 'visualiza',
    depositPreview: 'visualiza',
    transferPreviewMobile: 'transferir/visualiza',
    transferSpei: 'spei',
    addWorldPay: 'adicionar/cartao',
    addTrustly: 'adicionar/trustly',
    fillUserTrustly: 'adicionar/trustly/dados-do-usuario',
    addFiserv: 'adicionar/fiserv',
    annualSummary: 'resumo-anual',
  },

  selfExclusion: {
    path: 'autocensura',
    selfExclusion: 'autocensura',
    confirm: 'confirmar',
    limits: {
      path: 'limites',
      max: {
        path: 'maximos',
        remove: {
          path: 'eliminar',
          info: 'informacão',
          questionnaire: 'questionario',
        },
      },
    },
  },

  state: {
    path: 'estado',
    select: 'selecionar',
  },

  groups: {
    base: 'grupo',
    balance: {
      base: 'saldo',
      load: 'carregar',
      loadPending: 'carregar-pendente',
      request: 'solicitar',
      loadRequire: 'carregar-e-solicitar',
      distribute: 'distribuir',
      moneyPrizeBox: 'caixa-de-premio-em-dinheiro',
      moneyPrizeBoxHistory: 'historico',
      distributeMoneyPrizeBox: 'distribuir-premio-em-dinheiro',
      transferMoneyPrizeBox: 'transferir-premio-em-dinheiro',
    },
    play: 'jogar',
    games: 'jogos',
    tickets: 'ingressos',
    ticket: 'ingresso',
    chat: 'bate-papo',
    join: 'entrar',
    edit: 'editar',
    members: {
      base: 'membros',
      add: 'adicionar',
      sms: 'sms',
      contacts: 'contatos-do-google',
    },
    options: 'opcoes',
    new: {
      base: 'novo',
      image: 'imagem',
      members: 'membros',
      balance: 'saldo',
      load: 'carregar',
      booths: 'estandes',
    },
  },

  notFound: '404',

  desktop: {
    ticket: 'ingresso',
    sponsor: 'patrocinador',
    news: 'noticias',
    newsArticle: 'noticias',
    results: 'resultados/:id',
    resultSegment: 'resultados',
    balance: 'saldo',
    profile: 'perfil-do-usuario',
    kyc: 'kyc',

    money: {
      card: 'carregar/cartao',
      deposit: 'carregar/depositar',
      activities: 'atividades',
      annualSummary: 'resumo-anual',
      load: 'carregar',
      unload: 'descarregar',
      tpv: 'tpv',
      bizum: {
        path: 'bizum',
        config: 'configuracao',
        tpv: 'carregar',
      },
      mbWay: {
        path: 'mb-way',
        config: 'configuracão',
        tpv: 'carregar',
      },
      requestGreatPrize: 'request-great-prize',
      requestGreatPrizeBigPrizeParam: 'premio-grande',
      requestGreatPrizeMediumPrizeByIntegratorParam: 'premio-medio',
    },

    shipments: {
      shipComplete: 'envio-concluido',
      collectionComplete: 'colecao-concluida',
    },

    user: {
      data: 'dados',
      phone: 'telefone',
      sms: 'sms',
      smsCodeParam: 'codigo',
      kyc: 'identidade',
      login: 'login',
    },
  },
  mobile: {
    push: 'notificacoes-push',
    blockedNotifications: 'notificacoes-bloqueadas',
    installation: 'instalacoes',
    load: 'saldo/carregar',
    unload: 'dinheiro/descarga',
    annualSummary: 'dinheiro/resumo-anual',
    requestGreatPrize: 'dinheiro/pedido-grande-premio',
    requestGreatPrizeTicket: 'ingresso',
    requestGreatPrizeBigPrizeParam: 'premio-grande',
    requestGreatPrizeMediumPrizeByIntegratorParam: 'premio-medio',
    clubHelp: 'jogar/clubes/ajuda',
    googleContactsClub:
      'jogar/clubes/:gameId/:raffleId/:tabId/compartilhar/google-contatos',
    clubsList: 'jogar/clubes/:gameId/:raffleId',
    newsArticle: 'usuario/news/:newsId',
    init: 'usuario/init',
    kyc: 'usuario/identidade',
    sponsorFriends: 'usuario/patrocinador/amigos',
    sponsorHelp: 'usuario/patrocinador/ajuda',
    sponsorSms: 'usuario/patrocinador/sms',
    googleContacts: 'usuario/contatos/google',
    login: 'usuario/login',
    rememberPwd: 'usuario/login/lembrar',
    rememberSegment: 'lembrar',
    userPhone: 'usuario/telefone',
    userPhoneSegment: 'telefone',
    userData: 'usuario/dados',
    userImage: 'usuario/perfil/imagem',
    userSms: 'usuario/sms',
    smsSegment: 'sms',
    user: 'usuario',
    proof: 'ingressos/ingresso/:id/proff',
    shareTicket: 'ingressos/ingresso/:id/compartilhar',
    sharePercents: 'ingressos/ingresso/:id/compartilhar/porcentagens',
    shareOptions: 'ingressos/ingresso/:id/compartilhar/opcoes',
    ticketsByRaffle: 'ingressos/sorteio',

    main: {
      money: 'dinheiro',
      tickets: 'ingressos',
      ticket: 'ingresso',
      results: 'resultados',
      result: 'resultado',
      games: 'jogos',
      groups: 'grupos',
      apk: 'apk',
      menu: 'menu',
    },

    boothSelector: {
      options: 'opcoes',
    },
    favouriteBooth: {
      options: 'opciones',
    },

    scanner: {
      help: 'ajuda',
      game: ':id',
      raffle: ':id/:raffleId',
      manual: 'manual',
    },

    results: {
      result: ':id',
      score: 'resultados',
    },

    money: {
      tpv: 'tpv',
      bizum: 'bizum',
      mbWay: 'mb-way',
    },

    shipments: {
      address: 'endereco',
    },

    tuloteroSlides: {
      profile: 'perfil',
      sponsor: 'patrocinador',
      news: 'noticias',
    },

    sponsorContacts: {
      ranking: 'classificacao',
      achieved: 'alcancado',
      contacts: 'contatos',
      friends: 'amigos-da-tulotero',
      help: 'ajuda',
    },
  },
};
